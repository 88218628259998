/**
 * Auth related api client
 */
import { authServiceClient } from './authServiceClient';
import { AuthClient } from './types';

const authClient: AuthClient = authServiceClient();

/**
 * Singleton auth client
 */
const getAuthClient = (): AuthClient => {
  return authClient;
};

export const auth = getAuthClient();
