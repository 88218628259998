import { useNavigate as useNavigate_Tanstack, useRouter as useRouter_Tanstack } from '@tanstack/react-router';
import { type NavigateOptions, type To, useNavigate as useNavigate_RR } from 'react-router';

type NavigateType = typeof useNavigate_RR;

const useNavigateWrapper: NavigateType = () => {
  const { history } = useRouter_Tanstack();
  const navigate = useNavigate_Tanstack();
  return (to: To | number, options?: NavigateOptions): void => {
    if (typeof to === 'number') {
      history.go(to);
      return;
    }

    if (typeof to !== 'string') {
      // eslint-disable-next-line i18next/no-literal-string
      throw new Error('Navigation when not using a string is unsupported');
    }

    // eslint-disable-next-line no-void
    void navigate({ to, ...options });
  };
};

export const useNavigate_MIGRATION: NavigateType =
  process.env.VITE_TANSTACK_NAVIGATION != null ? useNavigateWrapper : useNavigate_RR;
