import {
  ExpertAuditDTO,
  ExpertAuditResponseDTO,
  getExpertAuditPdf,
  getSiteExpertAuditData,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import i18n from 'i18n';
import nullthrows from 'nullthrows';

import { convertAxiosResponse } from '../../util/convertAxiosResponse';
import { DateFormat, formatDate } from '../../util/formatDate';

const t = i18n.getFixedT(null, 'reporting');

enum ExpertAuditCacheKeys {
  All = 'all_audits',
}

export const useGetAllSiteExpertAudits = (siteId: string): UseQueryResult<ExpertAuditResponseDTO[], AxiosError> =>
  useQuery({
    queryKey: [ExpertAuditCacheKeys.All, siteId],
    queryFn: () => convertAxiosResponse(getSiteExpertAuditData({ path: { id: nullthrows(siteId) } })),
    enabled: !!siteId,
  });

export const useDownloadExpertAuditPDF = (): UseMutationResult<unknown, AxiosError, ExpertAuditDTO> => {
  return useMutation({
    mutationFn: (dto) =>
      convertAxiosResponse(
        getExpertAuditPdf({
          path: { id: dto.id },
          responseType: 'blob',
          transformResponse: (data: Blob) => {
            // name is similar to how it is displayed in audit table
            const name = t('useDownloadExpertAuditPDF.downloadCsvFileName', 'expert_audit_{{date}}.pdf', {
              date: formatDate(dto.auditStatusUpdateDate, DateFormat.SnakeShortDateYear),
            });

            // create the pdf and object url
            const pdfFile = new File([data], name, { type: 'application/pdf' });
            const url = URL.createObjectURL(pdfFile);

            // create the link and click it
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            a.click();

            // clean up
            URL.revokeObjectURL(url);
          },
        }),
      ),
  });
};
