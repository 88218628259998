import { SiteDTO, SubscriptionDTO, UserDTO } from '@audioeye/mono-client';
import { datadogRum } from '@datadog/browser-rum-slim';

import { storage } from '../storage';
import { getQueryParam } from '../url';
import { AnalyticsProperties } from './types';

export const initAnalytics = () => {
  // eslint-disable-next-line i18next/no-literal-string
  const analyticsEnabledParam = getQueryParam('ae_analytics_enabled');

  if (analyticsEnabledParam) {
    const disableAnalytics = analyticsEnabledParam === '0';
    storage.local.set('disableAnalytics', disableAnalytics);
  }
  const disableAnalytics = storage.local.get('disableAnalytics');

  if (!disableAnalytics) {
    datadogRum.startSessionReplayRecording();
  }
};

export const track = (eventName: string, properties?: AnalyticsProperties) => {
  datadogRum.addAction(eventName, properties ?? {});
};

export type UserSiteToPropertiesFnType = (
  user: UserDTO,
  site: SiteDTO,
  subscription?: SubscriptionDTO,
) => AnalyticsProperties;

export type CommonPropertiesFnType = (
  category: string,
  otherProps?: UserSiteToPropertiesFnType,
) => UserSiteToPropertiesFnType;

export const commonPropertiesFn: CommonPropertiesFnType = (category, otherProps) => (user, site, subscription) => ({
  category,
  userId: user.id,
  site_id: site.id,
  site_url: site.domain,
  ...(otherProps && otherProps(user, site, subscription)),
});
