import {
  CancellationReason,
  cancelUserSiteSubscription,
  ChargebeeInvoicePaginatedResponseDTO,
  ChargebeeInvoiceQuery,
  createSite,
  createSites,
  CreateSitesDTO,
  deleteSite,
  getReportShare,
  getSite,
  getSiteAttestation,
  getSiteCount,
  getSiteDomainForLegacyId,
  getSiteGroupTemplateSiteById,
  getSites,
  getSiteServiceLevel,
  getSiteValidity,
  getStarReportLink,
  getTrustedCertificationLink,
  getUserChargebeeInvoices,
  legalFormSubmit,
  LegalFormSubmitDto,
  MutateSiteDTO,
  MutateSubscriptionDTO,
  QuerySitePaginateEntitiesDTO,
  ReportShareDto,
  SiteDTO,
  SitePaginatedResponseDTO,
  SiteSubscriptionAttestationResponseDTO,
  SiteValidityResponseDto,
  StarReportLinkDto,
  SubscriptionDTO,
  syncSubscription,
  transferSite,
  TransferSiteDTO,
  updateSiteConfig,
  UpdateSiteConfigDto,
  updateSiteUrl,
  updateSubscription,
  updateUserSiteSubscription,
  UpdateUserSiteSubscriptionDto,
  UserSiteResponseDto,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isUndefined, omitBy } from 'lodash';
import nullthrows from 'nullthrows';

import { convertAxiosResponse } from '../../util/convertAxiosResponse';
import { SubscriptionCacheKeys } from '../subscription/subscriptionQueries';
// eslint-disable-next-line import/no-cycle

export enum SiteCacheKeys {
  SiteAsAdmin = 'site_as_admin',
  LegacySiteDomain = 'legacy_site_domain',
  UserSite = 'user_site',
  UserSiteBySiteGroupId = 'user_site_by_site_group_id',
  UserSitesPaginated = 'user_sites_paginated',
  SitesPaginated = 'sites_paginated',
  SiteAttestation = 'site_attestation',
  SiteCount = 'site_count',
  SinglePageScan = 'single_page_scan',
  SiteInvoices = 'site_invoices',
  SiteIssueReportingData = 'site_issue_reporting_data',
  SiteIssueReportingDataByCode = 'site_issue_reporting_data_by_code',
  SitePageIssueReportingData = 'site_page_issue_reporting_data',
  SiteServiceLevel = 'site_service_level',
  SiteURLValid = 'site_url_valid',
  AeScriptSiteGraderDetection = 'ae_script_site_grader_detection',
  ReportShare = 'report_share',
  RecentlyViewedSites = 'recently_viewed_sites',
}

type GetSiteDto = { siteId: string };

const clearCacheOnMutation = async (queryClient: QueryClient, siteId?: string): Promise<void> => {
  // Clear out all the sites queries when there is a mutation
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSite, siteId] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.SiteCount] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSitesPaginated] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.SitesPaginated] });
  await queryClient.invalidateQueries({ queryKey: [SubscriptionCacheKeys.All] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSitesPaginated], exact: false });
  await queryClient.invalidateQueries({ queryKey: [SubscriptionCacheKeys.SubscriptionById] });
};

export const useGetSite = (siteId: string | null | undefined): UseQueryResult<SiteDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.UserSite, siteId],
    queryFn: () => convertAxiosResponse(getSite({ path: { id: nullthrows(siteId) } })),
    enabled: !!siteId,
  });

export const useLoadSiteDomainForLegacyId = (legacyId: number | undefined): UseQueryResult<string, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.LegacySiteDomain, legacyId],
    queryFn: () => convertAxiosResponse(getSiteDomainForLegacyId({ path: { legacyId: nullthrows(legacyId) } })),
    enabled: !!legacyId,
    retry: false,
  });

export const useLoadSiteBySiteGroupId = (siteGroupId: string | undefined): UseQueryResult<SiteDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.UserSiteBySiteGroupId, siteGroupId],
    queryFn: () => convertAxiosResponse(getSiteGroupTemplateSiteById({ path: { id: nullthrows(siteGroupId) } })),
    enabled: !!siteGroupId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export const useGetSites = (
  params: QuerySitePaginateEntitiesDTO,
  isEnabled?: boolean,
): UseInfiniteQueryResult<InfiniteData<SitePaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SiteCacheKeys.UserSitesPaginated, params],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(
        getSites({
          query: omitBy(
            {
              pageSize: 25,
              orderBy: ['created'],
              ...omitBy(params, isUndefined),
              ...(pageParam == null ? {} : { cursor: pageParam }),
            },
            isUndefined,
          ),
        }),
      ),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    placeholderData: (prevData) => prevData,
    enabled: isEnabled ?? true,
    initialPageParam: '',
  });

export const useGetSiteServiceLevel = (siteId: string | null | undefined) =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteServiceLevel, siteId],
    queryFn: () => convertAxiosResponse(getSiteServiceLevel({ path: { id: nullthrows(siteId) } })),
    enabled: !!siteId,
    retry: false,
  });

export const useGetSiteURLValidity = (url: string): UseQueryResult<SiteValidityResponseDto, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteURLValid, url],
    queryFn: () => convertAxiosResponse(getSiteValidity({ query: { url } })),
    enabled: !!url,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export const useProvisionUserSite = (): UseMutationResult<SiteDTO, AxiosError, MutateSiteDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createSite({ body: dto })),
    onSuccess: async (result) => {
      await clearCacheOnMutation(queryClient, result.id);
    },
  });
};

export const useCreateSites = (): UseMutationResult<unknown, AxiosError, CreateSitesDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createSites({ body: dto })),
    onSuccess: async () => {
      await clearCacheOnMutation(queryClient);
    },
  });
};

export const useUpdateUserSite = (): UseMutationResult<
  SiteDTO,
  AxiosError,
  GetSiteDto & UpdateUserSiteSubscriptionDto
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteId, ...dto }) =>
      convertAxiosResponse(
        updateUserSiteSubscription({
          path: {
            id: siteId,
          },
          body: dto,
        }),
      ),
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useUpdateSiteUrl = (): UseMutationResult<
  UserSiteResponseDto,
  AxiosError,
  { siteId: string; url: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteId, url }) => convertAxiosResponse(updateSiteUrl({ path: { id: siteId }, body: { url } })),
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useUpdateUserSiteConfig = (): UseMutationResult<SiteDTO, AxiosError, GetSiteDto & UpdateSiteConfigDto> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteId, ...dto }) => convertAxiosResponse(updateSiteConfig({ path: { id: siteId }, body: dto })),
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useCancelSite = (): UseMutationResult<
  SiteDTO,
  AxiosError,
  GetSiteDto & { subscriptionId: string; cancelReason?: CancellationReason }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteId, cancelReason }) =>
      convertAxiosResponse(cancelUserSiteSubscription({ path: { id: siteId }, query: { cancelReason } })),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({
        queryKey: [SubscriptionCacheKeys.SubscriptionById, params.subscriptionId],
      });
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};
export const useTransferSite = (): UseMutationResult<SiteDTO, AxiosError, GetSiteDto & TransferSiteDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteId, subBrandId }) =>
      convertAxiosResponse(
        transferSite({
          path: { id: siteId },
          body: {
            subBrandId,
          },
        }),
      ),
    onSuccess: async (_, params) => {
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useDeleteSite = (): UseMutationResult<unknown, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (siteId) => convertAxiosResponse(deleteSite({ path: { id: siteId } })),
    onSuccess: async (_, params) => {
      await clearCacheOnMutation(queryClient, params);
    },
  });
};

export const useSyncSiteSubscription = (): UseMutationResult<unknown, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (subscriptionId) => convertAxiosResponse(syncSubscription({ path: { id: subscriptionId } })),
    onSuccess: async (_, siteId) => {
      await clearCacheOnMutation(queryClient, siteId);
    },
  });
};

export const useGetSiteAttestation = (
  siteId: string | undefined,
): UseQueryResult<SiteSubscriptionAttestationResponseDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteAttestation, siteId],
    queryFn: () => convertAxiosResponse(getSiteAttestation({ path: { id: nullthrows(siteId) } })),
    enabled: siteId != null,
  });

export const useSubmitLegalSupportEmail = (): UseMutationResult<
  unknown,
  AxiosError,
  { userId: string; data: LegalFormSubmitDto }
> =>
  useMutation({
    mutationFn: ({ userId, data }) => convertAxiosResponse(legalFormSubmit({ path: { id: userId }, body: data })),
  });

export const useGetStarPlanLink = (): UseMutationResult<StarReportLinkDto, AxiosError, GetSiteDto> =>
  useMutation({ mutationFn: ({ siteId }) => convertAxiosResponse(getStarReportLink({ path: { id: siteId } })) });

export const useGetTrustedCertification = (siteId: string): UseQueryResult<Blob, AxiosError> =>
  useQuery({
    queryKey: [siteId],
    queryFn: () => convertAxiosResponse(getTrustedCertificationLink({ path: { id: siteId } })),
  });

export const useGetSiteCount = (
  dto: QuerySitePaginateEntitiesDTO,
  isEnabled?: boolean,
): UseQueryResult<number, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteCount, dto],
    queryFn: () => convertAxiosResponse(getSiteCount({ query: dto })),
    enabled: isEnabled,
  });

export const useFetchReportShareParams = ({
  reportShareId,
  enabled = true,
}: {
  reportShareId: string;
  enabled?: boolean;
}): UseQueryResult<ReportShareDto, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.ReportShare, reportShareId],
    queryFn: () => convertAxiosResponse(getReportShare({ path: { shareId: reportShareId } })),
    enabled,
  });

export const useGetInvoices = ({
  subBrandId,
  pageSize,
  cursor,
}: ChargebeeInvoiceQuery & { subBrandId: string }): UseInfiniteQueryResult<
  InfiniteData<ChargebeeInvoicePaginatedResponseDTO & { subBrandId: string }>,
  AxiosError
> =>
  useInfiniteQuery({
    queryKey: [SiteCacheKeys.SiteInvoices, subBrandId, pageSize, cursor],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(
        getUserChargebeeInvoices({
          path: { id: subBrandId },
          query: { pageSize, cursor: pageParam == null ? undefined : pageParam },
        }),
      ),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
  });

export const useUpdateSubscription = (): UseMutationResult<
  SubscriptionDTO,
  AxiosError,
  { id: string; dto: MutateSubscriptionDTO }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, dto }) => convertAxiosResponse(updateSubscription({ path: { id }, body: dto })),
    onSuccess: async (result, params) => {
      await queryClient.invalidateQueries({
        queryKey: [SubscriptionCacheKeys.SubscriptionById, params.id],
      });
      await clearCacheOnMutation(queryClient);
      return result;
    },
  });
};
