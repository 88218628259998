import { Box, Heading, Icon, Link, Text } from '@audioeye/inclusive-ui';
import { useDynamicConfig } from '@statsig/react-bindings';
import { rem } from 'polished';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { DynamicConfig } from 'types/util';
import { DateFormat, formatDate, formatTime } from 'util/formatDate';

import * as style from '../../layouts/BasicLayout/BasicLayout.style';

type UnderConstructionTimes = {
  endTime: string;
  startTime: string;
};

/**
 * Page for displaying whenever the site is under construction
 * Ex: db migrations, major updates, etc.
 *
 * Date/Time is controlled via
 *  https://console.statsig.com/2wzWdy0ATLZvqO6415RgUG/dynamic_configs/under_construction_time
 * Update the start and end time there to update this page
 * The page itself will display only if the gate `should_show_construction_page` is true
 *
 */
export const SiteUnderConstructionPage: React.FC = () => {
  const { t } = useTranslation();
  const { value: underConstructionTimeFrames } = useDynamicConfig(DynamicConfig.UnderConstructionTime);
  const { endTime, startTime } = underConstructionTimeFrames as UnderConstructionTimes;

  // Fallbacks in case the times are not set
  const endTimeString = endTime
    ? t('SiteUnderConstructionPage.endTime', '{{formattedDate}} at {{formattedTime}}', {
        formattedDate: formatDate(endTime, DateFormat.Short),
        formattedTime: formatTime(endTime),
      })
    : t('SiteUnderConstructionPage.toBeDetermined', 'TBD');
  const startTimeString = startTime
    ? t('SiteUnderConstructionPage.startTime', '{{formattedDate}} at {{formattedTime}}', {
        formattedDate: formatDate(startTime, DateFormat.Short),
        formattedTime: formatTime(startTime),
      })
    : t('SiteUnderConstructionPage.toBeDetermined', 'TBD');

  const content = useMemo(
    () => (
      <Box css={style.root}>
        <Box align="center" css={{ justifyContent: 'center' }} width="100%" pad="xlarge" gap="medium" direction="row">
          <Icon type="status-warning" size={rem(96)} />
          <Box direction="column">
            <Heading level="1">{t('SiteUnderConstructionPage.heading', 'Site Under Construction')}</Heading>
            <Text variant="reg">
              <Trans ns="dashboard" i18nKey="SiteUnderConstructionPage.statusPageLink">
                We are currently working on this site. You can view more details{' '}
                <Link target="_blank" href="https://status.audioeye.com/">
                  here.
                </Link>
              </Trans>
            </Text>
            <Text variant="reg">
              <Trans values={{ startTimeString }} i18nKey="SiteUnderConstructionPage.startTime">
                <strong>Start Time:</strong> {'{{startTimeString}}'}
              </Trans>
            </Text>
            <Text variant="reg">
              <Trans values={{ endTimeString }} i18nKey="SiteUnderConstructionPage.endTime">
                <strong>Estimated End Time:</strong> {'{{endTimeString}}'}
              </Trans>
            </Text>
          </Box>
        </Box>
      </Box>
    ),
    [endTimeString, startTimeString],
  );

  return (
    <>
      <Helmet title={t('SiteUnderConstructionPage.title', 'Site Under Construction')} />
      {content}
    </>
  );
};
