/* eslint-disable max-len */
import { APP_CONFIG } from 'config';
import React from 'react';
import Helmet from 'react-helmet';

export function AudioEyeScript() {
  // eslint-disable-next-line i18next/no-literal-string
  const hashToUse = APP_CONFIG.site.siteHash;
  return (
    <Helmet>
      <script type="text/javascript">{
        // eslint-disable-next-line i18next/no-literal-string
        `window.__AudioEyeSiteHash = '${hashToUse}';`
      }</script>
      <script type="text/javascript" src="https://wsmcdn.audioeye.com/aem.js" />
    </Helmet>
  );
}
