/**
 * Api http client with global config
 * TODO: auth header defaults for authenticated requests
 * Consider custom authenticated client redux middleware?
 */
import { AuthenticationApi, Configuration } from '@audioeye/auth-client';
import { client as monoClient } from '@audioeye/mono-client';
import { datadogLogs } from '@datadog/browser-logs';
import axios, { type AxiosError, AxiosRequestConfig, type InternalAxiosRequestConfig } from 'axios';
import { AEStatsigClient } from 'util/AEStatsigClient';
import { StatsigOverrideAdaptor } from 'util/StatsigOverrideAdaptor';

import { APP_CONFIG } from '../../config';
import { AuthStorage } from './auth/AuthStorage';
import { getClientBearerToken } from './auth/getClientBearerToken';

monoClient.setConfig({
  baseURL: APP_CONFIG.api.url,
});
const client = monoClient.instance;

export const authClient = axios.create({
  baseURL: APP_CONFIG.auth.serviceUrl,
});
export const siteGraderClient = axios.create({
  baseURL: APP_CONFIG.siteGrader.url,
});

const authenticationApi = new AuthenticationApi(
  new Configuration({
    basePath: APP_CONFIG.auth.serviceUrl,
  }),
  APP_CONFIG.auth.serviceUrl,
  axios.create({
    baseURL: APP_CONFIG.auth.serviceUrl,
  }),
);
const fetchBearerHeader = async () => {
  const authSession = AuthStorage.getSession();
  if (!authSession) {
    return;
  }

  try {
    const { data } = await authenticationApi.currentSession({
      ...authSession,
      shouldForceRefresh: true,
    });
    AuthStorage.setSession(data);
    // eslint-disable-next-line i18next/no-literal-string
    return `Bearer ${data.accessToken}`;
  } catch (_error) {
    /* The token has expired and we need to clear out state */
    AuthStorage.clearSession();
  }
};

const REQUEST_INTERCEPTOR = async (req: InternalAxiosRequestConfig<any>) => {
  // Conditionally attach bearer auth
  const token = await getClientBearerToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }

  try {
    const { gate, dynamicConfig } = (
      AEStatsigClient.getContext().options.overrideAdapter as StatsigOverrideAdaptor
    ).getAllOverrides();
    if (gate) {
      // Via @audioeye/nestjs-statsig
      req.headers['x-statsig-overrides'] = JSON.stringify(gate);
    }
    if (dynamicConfig) {
      req.headers['x-statsig-dynamic-config-overrides'] = JSON.stringify(dynamicConfig);
    }
  } catch (_) {
    /* ignore me */
  }

  return req;
};
client.interceptors.request.use(REQUEST_INTERCEPTOR);
authClient.interceptors.request.use(REQUEST_INTERCEPTOR);
siteGraderClient.interceptors.request.use(REQUEST_INTERCEPTOR);

let fetchingPromise: Promise<string | undefined> | null = null;
const RESPONSE_INTERCEPTOR = async (error: AxiosError) => {
  // Log network errors to datadog
  const status = error?.response?.status;
  if (!status || status >= 500) {
    datadogLogs.logger.error(error.message || 'Network error', error);
  }

  const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };
  if ((status === 401 || !status) && !originalRequest._retry) {
    originalRequest._retry = true;

    // update both the default and the in-flight request
    if (status === 401) {
      fetchingPromise = fetchingPromise || fetchBearerHeader();
      await fetchingPromise;
      fetchingPromise = null;
    }
    return client(originalRequest);
  }
  return Promise.reject(error);
};
client.interceptors.response.use((response) => response, RESPONSE_INTERCEPTOR);
authClient.interceptors.response.use((response) => response, RESPONSE_INTERCEPTOR);
siteGraderClient.interceptors.response.use((response) => response, RESPONSE_INTERCEPTOR);
