module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AudioEye Customer Portal","short_name":"AudioEye","start_url":"/login","background_color":"#0074BB","theme_color":"#fff","display":"standalone","icon":"./src/assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"358cdd6575ac5572e4f429a827fb047d"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://portal.audioeye.com/"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../plugins/gatsby-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":100,"enabled":true,"service":"cx-portal","env":"prod","proxy":"https://dd.audioeye.com","applicationId":"3237fbd7-7574-4465-a0c5-cbe47a73dc22","clientToken":"pub78f2fce57c9dbc591c9fb41f87a85369","sessionReplaySampleRate":0,"trackInteractions":true,"defaultPrivacyLevel":"mask-user-input","trackSessionAcrossSubdomains":true,"useCrossSiteSessionCookie":true,"version":"v14.279.34","allowedTracingUrls":["https://mono.audioeye.com","https://auth.audioeye.com","https://grader.audioeye.com"],"rum":{"applicationId":"3237fbd7-7574-4465-a0c5-cbe47a73dc22","clientToken":"pub78f2fce57c9dbc591c9fb41f87a85369"},"logs":{"clientToken":"pub78f2fce57c9dbc591c9fb41f87a85369"}},
    },{
      plugin: require('../../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Epilogue","file":"https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap"},{"name":"Schibsted Grotesk","file":"https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400..700;1,400..700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
