export enum TranslationNamespaces {
  Account = 'account',
  Authentication = 'authentication',
  Checkout = 'checkout',
  Core = 'translation',
  Dashboard = 'dashboard',
  Installation = 'installation',
  Legal = 'legal',
  Reporting = 'reporting',
  Springtime = 'springtime',
}
