import { SSOConfigurationScope } from '@audioeye/auth-client';
import {
  createPresentationConfig,
  getPresentationConfig,
  getPresentationConfigBySingleSignOnConfigId,
  MutatePresentationConfigDTO,
  PresentationConfigDTO,
  removePresentationConfig,
  updatePresentationConfig,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useConvertDefaultComponentValuesToDTO } from 'hooks/useConvertDefaultComponentValuesToDTO';
import nullthrows from 'nullthrows';
import { VENASTA_SSO_CONFIG_IDS } from 'util/VENDASTA_SSO_CONFIG_IDS';

import { convertAxiosResponse } from '../util/convertAxiosResponse';
import { IntegrationCacheKeys } from './integrationQueries';
import { useGetPublicSSOConfigurationById } from './ssoQueries';

enum PresentationConfigCacheKeys {
  ByID = 'presentation_config_by_id',
  BySingleSignOnID = 'presentation_config_by_single_sign_on_id',
}

export const useGetPresentationConfigById = (
  presentationConfigId: string | undefined | null,
): UseQueryResult<PresentationConfigDTO, AxiosError> =>
  useQuery({
    queryKey: [PresentationConfigCacheKeys.ByID, presentationConfigId],
    queryFn: () => convertAxiosResponse(getPresentationConfig({ path: { id: nullthrows(presentationConfigId) } })),
    enabled: !!presentationConfigId,
  });

export const useGetPresentationConfigBySingleSignOnConfigId = (
  singleSignOnConfigId: string | undefined | null,
): UseQueryResult<PresentationConfigDTO, AxiosError> => {
  const queryClient = useQueryClient();

  const { data: publicSSOConfig } = useGetPublicSSOConfigurationById(singleSignOnConfigId);

  const { DEFAULT_PRESENTATION_CONFIG, VENDASTA_DEFAULT_PRESENTATION_CONFIG } = useConvertDefaultComponentValuesToDTO();

  return useQuery({
    queryKey: [PresentationConfigCacheKeys.BySingleSignOnID, singleSignOnConfigId],
    queryFn: async () => {
      if (singleSignOnConfigId && VENASTA_SSO_CONFIG_IDS.includes(singleSignOnConfigId)) {
        return VENDASTA_DEFAULT_PRESENTATION_CONFIG;
      }
      const response = await convertAxiosResponse(
        getPresentationConfigBySingleSignOnConfigId({ path: { ssoConfigurationId: nullthrows(singleSignOnConfigId) } }),
      );
      queryClient.setQueryData([PresentationConfigCacheKeys.ByID, response.id], response);
      return response;
    },
    initialData: () => {
      if (singleSignOnConfigId && VENASTA_SSO_CONFIG_IDS.includes(singleSignOnConfigId)) {
        return VENDASTA_DEFAULT_PRESENTATION_CONFIG;
      }
      return DEFAULT_PRESENTATION_CONFIG;
    },
    enabled:
      !!singleSignOnConfigId &&
      // If this is null, it could mean that it's legacy Vendasta
      (VENASTA_SSO_CONFIG_IDS.includes(singleSignOnConfigId) ||
        (publicSSOConfig != null && publicSSOConfig.configurationScope !== SSOConfigurationScope.ORGANIZATION)),
    retry: false,
  });
};

export const useCreatePresentationConfig = (): UseMutationResult<
  PresentationConfigDTO,
  AxiosError,
  MutatePresentationConfigDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createPresentationConfig({ body: dto })),
    onSuccess: async (data) => {
      queryClient.setQueryData([PresentationConfigCacheKeys.ByID, data.id], data);
      await queryClient.invalidateQueries({ queryKey: [IntegrationCacheKeys.ByID, data.integrationId] });
    },
  });
};

export const useUpdatePresentationConfig = (): UseMutationResult<
  PresentationConfigDTO,
  AxiosError,
  MutatePresentationConfigDTO & { id: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => convertAxiosResponse(updatePresentationConfig({ path: { id }, body: dto })),
    onSuccess: (data) => {
      queryClient.setQueryData([PresentationConfigCacheKeys.ByID, data.id], data);
    },
  });
};

export const useRemovePresentationConfig = (): UseMutationResult<
  unknown,
  AxiosError,
  { presentationConfigId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ presentationConfigId }) =>
      convertAxiosResponse(removePresentationConfig({ path: { id: presentationConfigId } })),
    onSuccess: async (_, { presentationConfigId }) => {
      await queryClient.invalidateQueries({ queryKey: [PresentationConfigCacheKeys.ByID, presentationConfigId] });
    },
  });
};
