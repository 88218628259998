import {
  createSiteCreationDefault,
  deleteSiteCreationDefault,
  getSiteCreationDefaultById,
  getSiteCreationDefaults,
  MutateSiteCreationDefaultsDto,
  QuerySiteCreationDefaultPaginateEntitiesDTO,
  SiteConfig,
  SiteCreationDefaultDTO,
  SiteCreationDefaultPaginatedResponseDTO,
  updateSiteCreationDefault,
  updateSiteCreationDefaultSiteConfig,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';

import { convertAxiosResponse } from '../../util/convertAxiosResponse';
import { SiteGroupCacheKeys } from '../siteGroups/siteGroupQueries';

enum SiteCreationDefaultCacheKeys {
  SiteCreationDefaults = 'site_creation_defaults',
  SiteCreationDefault = 'site_creation_default',
}

export const useGetSiteCreationDefaults = (
  dto: QuerySiteCreationDefaultPaginateEntitiesDTO,
): UseInfiniteQueryResult<InfiniteData<SiteCreationDefaultPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults, dto],
    queryFn: () => convertAxiosResponse(getSiteCreationDefaults({ query: dto })),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    initialPageParam: '',
  });

export const useGetSiteCreationDefaultById = (
  siteCreationDefaultId: string | null | undefined,
): UseQueryResult<SiteCreationDefaultDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, siteCreationDefaultId],
    queryFn: () =>
      convertAxiosResponse(getSiteCreationDefaultById({ path: { id: nullthrows(siteCreationDefaultId) } })),
    enabled: !!siteCreationDefaultId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

export const useCreateSiteCreationDefault = (): UseMutationResult<
  SiteCreationDefaultDTO,
  Error,
  MutateSiteCreationDefaultsDto
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createSiteCreationDefault({ body: dto })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

export const useUpdateSiteCreationDefault = (): UseMutationResult<
  SiteCreationDefaultDTO,
  Error,
  MutateSiteCreationDefaultsDto & { id: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) =>
      convertAxiosResponse(
        updateSiteCreationDefault({
          path: {
            id,
          },
          body: dto,
        }),
      ),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, params.id] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

export const useUpdateSiteCreationDefaultSiteConfig = (): UseMutationResult<
  SiteCreationDefaultDTO,
  Error,
  { siteCreationDefaultId: string; siteConfig: SiteConfig }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteCreationDefaultId, siteConfig }) =>
      convertAxiosResponse(
        updateSiteCreationDefaultSiteConfig({ path: { id: siteCreationDefaultId }, body: siteConfig }),
      ),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({
        queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, params.siteCreationDefaultId],
      });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

type DeleteSiteCreationDefaultParams = {
  siteCreationDefaultId: string;
};

export const useDeleteSiteCreationDefault = (): UseMutationResult<
  unknown,
  AxiosError,
  DeleteSiteCreationDefaultParams
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) =>
      convertAxiosResponse(deleteSiteCreationDefault({ path: { id: params.siteCreationDefaultId } })),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({
        queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, params.siteCreationDefaultId],
      });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};
