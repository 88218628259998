import {
  AddSitesToSiteGroup,
  addSitesToSiteGroup,
  createSiteGroup,
  CreateSiteGroupDTO,
  deleteSiteGroup,
  getIsConsentManagementEnabledStatusForSiteGroup,
  getSiteGroupById,
  getSiteGroupMetadata,
  GetSiteGroupMetadataDTO,
  getSiteGroups,
  getSiteGroupSitesById,
  GetSiteGroupSitesParamsDto,
  MutateSiteGroupDTO,
  promoteSiteGroupWorkingRems,
  QuerySiteGroupPaginateEntitiesDTO,
  refreshSiteConfigsForGroupId,
  RefreshSiteConfigsForGroupIdParamsDto,
  removeSitesFromSiteGroup,
  SiteGroupDTO,
  SiteGroupMetadataDTO,
  SiteGroupPaginatedResponseDTO,
  SiteGroupVersion,
  SitePaginatedResponseDTO,
  SitePaginatedResponseDto,
  updateIsConsentManagementEnabled,
  updateSiteGroup,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { getFilesKey, siteGroupOverviewKey } from 'state/rule_remediations/ruleRemediationQueries';
import { DeleteSiteGroupParams, MutateSitesToSiteGroupParams } from 'types/siteGroup';

import { SpringtimeMutationKeys } from '../../springtime/enums/enums';
import { convertAxiosResponse } from '../../util/convertAxiosResponse';
import { SiteCacheKeys } from '../sites/siteQueries';

export enum SiteGroupCacheKeys {
  SiteGroups = 'site_groups',
  SiteGroupSingle = 'site_group',
  SiteGroupMetadata = 'site_group_metadata',
  SiteGroupVersions = 'site_group_versions',
  SiteGroupSites = 'site_group_sites',
  SiteGroupSitesInfinite = 'site_group_sites_infinite',
  SiteGroupSitesCount = 'site_group_sites_count',
  SiteGroupUsers = 'site_group_users',
  SiteGroupConsentManagement = 'site_group_consent_management',
}

export const useLoadSiteGroupsPaginated = (
  params: QuerySiteGroupPaginateEntitiesDTO,
  isEnabled = true,
): UseInfiniteQueryResult<InfiniteData<SiteGroupPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroups, params],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(getSiteGroups({ query: { ...params, cursor: pageParam == null ? undefined : pageParam } })),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    initialPageParam: '',
  });

export const useLoadSiteGroupByID = (
  siteGroupId: string | null | undefined,
): UseQueryResult<SiteGroupDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroupSingle, siteGroupId],
    queryFn: () => convertAxiosResponse(getSiteGroupById({ path: { id: nullthrows(siteGroupId) } })),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!siteGroupId,
  });

export const useGetSiteGroupMetadata = (
  siteGroupId: string | null | undefined,
  query: GetSiteGroupMetadataDTO = {},
): UseQueryResult<SiteGroupMetadataDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroupMetadata, siteGroupId, query],
    queryFn: () => convertAxiosResponse(getSiteGroupMetadata({ path: { id: nullthrows(siteGroupId) } })),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!siteGroupId,
  });

export const useLoadSiteGroupSitesByID = ({
  siteGroupId,
  ...params
}: Omit<GetSiteGroupSitesParamsDto, 'siteGroupId'> & { siteGroupId?: string }): UseQueryResult<
  SitePaginatedResponseDto,
  AxiosError
> =>
  useQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroupSites, siteGroupId, params],
    queryFn: () =>
      convertAxiosResponse(
        getSiteGroupSitesById({
          path: {
            id: nullthrows(siteGroupId),
          },
          query: { ...params, siteGroupId: nullthrows(siteGroupId) },
        }),
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: siteGroupId != null,
  });

export type UseLoadSiteGroupSitesByIdInfiniteQuery = Omit<
  GetSiteGroupSitesParamsDto,
  'siteGroupId' | 'queryUnmappedSites'
> & {
  siteGroupId?: string;
  queryUnmappedSites?: boolean;
};

export const useLoadSiteGroupSitesByIdInfinite = ({
  siteGroupId,
  ...params
}: UseLoadSiteGroupSitesByIdInfiniteQuery): UseInfiniteQueryResult<
  InfiniteData<SitePaginatedResponseDTO>,
  AxiosError
> =>
  useInfiniteQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroupSitesInfinite, siteGroupId, params],
    queryFn: async ({ pageParam: { page, pageSize } }) =>
      convertAxiosResponse(
        getSiteGroupSitesById({
          path: {
            id: nullthrows(siteGroupId),
          },
          query: {
            ...params,
            queryUnmappedSites: params.queryUnmappedSites ?? false,
            page,
            pageSize,
            siteGroupId: nullthrows(siteGroupId),
          },
        }),
      ),
    getNextPageParam: ({ page, pageSize, totalPages }) => (page < totalPages ? { page: page + 1, pageSize } : null),
    getPreviousPageParam: ({ page, pageSize }) => ({ page: Math.max(page - 1, 1), pageSize }),
    placeholderData: (prevData) => prevData,
    enabled: siteGroupId != null,
    initialPageParam: { page: params.page ?? 1, pageSize: params.pageSize ?? 20 },
  });

export const useLoadSiteGroupSitesCount = ({
  siteGroupId,
  ...params
}: UseLoadSiteGroupSitesByIdInfiniteQuery): UseQueryResult<number, AxiosError> =>
  useQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroupSitesCount, siteGroupId, params],
    queryFn: async () => {
      const data = await convertAxiosResponse(
        getSiteGroupSitesById({
          path: {
            id: nullthrows(siteGroupId),
          },
          query: {
            ...params,
            queryUnmappedSites: params.queryUnmappedSites ?? false,
            page: 1,
            pageSize: 1,
            siteGroupId: nullthrows(siteGroupId),
          },
        }),
      );
      return data.totalCount;
    },
    enabled: siteGroupId != null,
  });

export const useGetIsConsentManagementEnabledStatusForSiteGroup = (
  siteGroupId: string | null | undefined,
  enabled = true,
): UseQueryResult<boolean, AxiosError> => {
  return useQuery({
    queryKey: [SiteGroupCacheKeys.SiteGroupConsentManagement, siteGroupId],
    queryFn: () =>
      convertAxiosResponse(getIsConsentManagementEnabledStatusForSiteGroup({ path: { id: nullthrows(siteGroupId) } })),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const usePromoteWorkingRemsBySiteGroupById = (): UseMutationResult<unknown, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (siteGroupId) => convertAxiosResponse(promoteSiteGroupWorkingRems({ path: { id: siteGroupId } })),
    onSuccess: async (_, siteGroupId) => {
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSingle, siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupMetadata, siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupVersions, siteGroupId] });
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId, SiteGroupVersion.WORKING),
      });
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId, SiteGroupVersion.ACTIVE),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.ACTIVE),
      });
    },
  });
};

export const useRefreshSiteConfigBySiteGroupId = (): UseMutationResult<
  unknown,
  AxiosError,
  RefreshSiteConfigsForGroupIdParamsDto
> =>
  useMutation({
    mutationFn: (dto) => convertAxiosResponse(refreshSiteConfigsForGroupId({ path: dto })),
  });

export const useCreateSiteGroup = (): UseMutationResult<SiteGroupDTO, AxiosError, CreateSiteGroupDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createSiteGroup({ body: dto })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

export const useUpdateSiteGroup = (): UseMutationResult<
  SiteGroupDTO,
  AxiosError,
  MutateSiteGroupDTO & { siteGroupId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, ...dto }) =>
      convertAxiosResponse(
        updateSiteGroup({
          path: {
            id: siteGroupId,
          },
          body: dto,
        }),
      ),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSingle, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupMetadata, params.siteGroupId] });
    },
  });
};

export const useDeleteSiteGroup = (): UseMutationResult<unknown, AxiosError, DeleteSiteGroupParams> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => convertAxiosResponse(deleteSiteGroup({ path: { id: params.siteGroupId } })),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSingle, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupMetadata, params.siteGroupId] });
    },
  });
};

export const useAddSitesToSiteGroup = (): UseMutationResult<
  unknown,
  AxiosError,
  AddSitesToSiteGroup & { siteGroupId: string; viewingSiteGroupId?: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, viewingSiteGroupId: _, ...dto }) =>
      convertAxiosResponse(addSitesToSiteGroup({ path: { id: siteGroupId }, body: dto })),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSites, params.siteGroupId] });
      await queryClient.invalidateQueries({
        queryKey: [SiteGroupCacheKeys.SiteGroupSitesInfinite, params.siteGroupId],
      });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSitesCount, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSingle, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupMetadata, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
      await queryClient.invalidateQueries({ queryKey: siteGroupOverviewKey(params.viewingSiteGroupId) });
    },
  });
};

export const useRemoveSitesFromSiteGroup = (): UseMutationResult<unknown, AxiosError, MutateSitesToSiteGroupParams> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, removeSitesFromSiteGroupDto }) =>
      convertAxiosResponse(
        removeSitesFromSiteGroup({
          path: { id: siteGroupId },
          body: removeSitesFromSiteGroupDto,
        }),
      ),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSites, params.siteGroupId] });
      await queryClient.invalidateQueries({
        queryKey: [SiteGroupCacheKeys.SiteGroupSitesInfinite, params.siteGroupId],
      });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSitesCount, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupSingle, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupMetadata, params.siteGroupId] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
      await queryClient.invalidateQueries({ queryKey: siteGroupOverviewKey(params.viewingSiteGroupId) });
    },
  });
};

export const useUpdateConsentManagementEnabledForSiteGroup = (): UseMutationResult<
  SiteGroupDTO,
  AxiosError,
  { siteGroupId: string; isConsentManagementEnabled: boolean }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, isConsentManagementEnabled }) =>
      convertAxiosResponse(
        updateIsConsentManagementEnabled({
          path: { id: siteGroupId },
          body: {
            isConsentManagementEnabled,
          },
        }),
      ),
    mutationKey: [SpringtimeMutationKeys.CHANGE_WEBSITE_STATUS],
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.SiteCount] });
      await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSitesPaginated] });
      await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.SitesPaginated] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroupConsentManagement] });
    },
  });
};
