import {
  MutateUserGroupDTO,
  MutateUserToUserGroupDTO,
  QueryUserGroupPaginateEntitiesDTO,
  QueryUserGroupUsersPaginatedEntitiesDTO,
  UserGroupDTO,
  UserGroupPaginatedResponseDTO,
  UserToUserGroupPaginatedResponseDTO,
} from '@audioeye/auth-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  addUsersToUserGroup,
  createUserGroup,
  getUserGroupById,
  getUserGroups,
  getUsersInUserGroup,
  removeUserGroup,
  removeUsersFromUserGroup,
  updateUserGroup,
} from '../services/api/auth/userGroup.api';

enum UserGroupCacheKey {
  All = 'all_user_groups',
  UsersInUserGroup = 'users_in_user_group',
  ById = 'user_group_by_id',
}

export const useGetUserGroups = (
  dto: QueryUserGroupPaginateEntitiesDTO,
  isEnabled?: boolean,
): UseInfiniteQueryResult<InfiniteData<UserGroupPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [UserGroupCacheKey.All, dto],
    queryFn: ({ pageParam }) => getUserGroups({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isEnabled ?? true,
    initialPageParam: '',
  });
export const useGetUsersInUserGroup = (
  dto: QueryUserGroupUsersPaginatedEntitiesDTO & { userGroupId: string },
  isEnabled?: boolean,
): UseInfiniteQueryResult<InfiniteData<UserToUserGroupPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [UserGroupCacheKey.UsersInUserGroup, dto],
    queryFn: ({ pageParam }) =>
      getUsersInUserGroup(dto.userGroupId, { ...dto, cursor: pageParam == null ? undefined : pageParam }),

    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: !!dto.userGroupId && isEnabled,
    initialPageParam: '',
  });

export const useGetUserGroupById = (userGroupId: string | null | undefined): UseQueryResult<UserGroupDTO, AxiosError> =>
  useQuery({
    queryKey: [UserGroupCacheKey.ById, userGroupId],
    queryFn: () => getUserGroupById(userGroupId || ''),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!userGroupId,
  });

export const useCreateUserGroup = (): UseMutationResult<UserGroupDTO, AxiosError, MutateUserGroupDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createUserGroup,
    onSuccess: async (userGroup) => {
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.All] });
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.ById, userGroup.id] });
    },
  });
};

export const useUpdateUserGroup = (): UseMutationResult<
  UserGroupDTO,
  AxiosError,
  { userGroupId: string } & MutateUserGroupDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => updateUserGroup(params.userGroupId, params),
    onSuccess: async (userGroup) => {
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.All] });
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.ById, userGroup.id] });
    },
  });
};

export const useRemoveUserGroup = (): UseMutationResult<void, AxiosError, { userGroupId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => removeUserGroup(params.userGroupId),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.All] });
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.ById, params.userGroupId] });
    },
  });
};

export const useAddUsersToUserGroup = (): UseMutationResult<
  void,
  AxiosError,
  { userGroupId: string } & MutateUserToUserGroupDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => addUsersToUserGroup(params.userGroupId, params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.UsersInUserGroup] });
    },
  });
};

export const useRemoveUsersFromUserGroup = (): UseMutationResult<
  void,
  AxiosError,
  { userGroupId: string } & MutateUserToUserGroupDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => removeUsersFromUserGroup(params.userGroupId, params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [UserGroupCacheKey.UsersInUserGroup] });
    },
  });
};
