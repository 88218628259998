import {
  getPlans,
  GetPlansResponseDto,
  getSubscriptionById,
  getSubscriptions,
  QuerySubscriptionPaginateEntitiesDTO,
  SubscriptionDTO,
  SubscriptionPaginatedResponseDTO,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';

import { convertAxiosResponse } from '../../util/convertAxiosResponse';
import { CHARGEBEE_MOCK_PLANS } from '../../util/mocks/CHARGEBEE_MOCK_PLANS';

export enum SubscriptionCacheKeys {
  All = 'all_subscriptions',
  SubscriptionPlans = 'subscription_plans',
  SubscriptionById = 'subscription_by_id',
}

export const useFetchSubscriptionPlans = (coupons?: string[]): UseQueryResult<GetPlansResponseDto[]> =>
  useQuery({
    queryKey: [SubscriptionCacheKeys.SubscriptionPlans, coupons],
    queryFn: () => {
      // Return mock data for e2e. If we try to use `page.route` it will prevent page caching which slows down all tests
      if (process.env.NODE_ENV === 'development' && process.env.GATSBY_ACTIVE_ENV === 'e2e') {
        return CHARGEBEE_MOCK_PLANS;
      }

      return convertAxiosResponse(getPlans({ query: { coupons: coupons ?? [] } }));
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useGetSubscriptionById = (
  subscriptionId: string | undefined | null,
): UseQueryResult<SubscriptionDTO, AxiosError> =>
  useQuery({
    queryKey: [SubscriptionCacheKeys.SubscriptionById, subscriptionId],
    queryFn: () => convertAxiosResponse(getSubscriptionById({ path: { id: nullthrows(subscriptionId) } })),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: !!subscriptionId,
  });

export const useGetSubscriptions = (
  dto: QuerySubscriptionPaginateEntitiesDTO,
): UseInfiniteQueryResult<InfiniteData<SubscriptionPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SubscriptionCacheKeys.All, dto],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(getSubscriptions({ query: { ...dto, cursor: pageParam == null ? undefined : pageParam } })),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    initialPageParam: '',
  });
