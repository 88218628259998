/* eslint-disable i18next/no-literal-string */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend'; // have a own http fallback
import LocizeBackend from 'i18next-locize-backend'; // load from service
import { initReactI18next } from 'react-i18next';

import { isSpringtimeUrl } from './util/isSpringtimeUrl';
import { prefixPostProcessor } from './util/prefixPostProcessor';
import { TranslationNamespaces } from './util/TranslationNamespaces';

const LOCIZE_PROJECT_ID = process.env.GATSBY_LOCIZE_PROJECT_ID;

const ENABLE_LOCIZE = LOCIZE_PROJECT_ID != null && LOCIZE_PROJECT_ID !== '';

const LOCIZE_VERSION =
  process.env.GATSBY_LOCIZE_VERSION != null && process.env.GATSBY_LOCIZE_VERSION
    ? process.env.GATSBY_LOCIZE_VERSION
    : 'latest';

export const SUPPORTED_LANGUAGES =
  process.env.GATSBY_ACTIVE_ENV === 'production' ? ['en'] : ['en', 'de', 'es', 'fr', 'it', 'nl', 'pt', 'testing'];

const i18n = i18next.createInstance() as typeof i18next;
// eslint-disable-next-line @typescript-eslint/no-floating-promises
(process.env.JEST_WORKER_ID != null ? i18n : i18n.use(ChainedBackend))
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(prefixPostProcessor)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<ChainedBackendOptions>({
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: false, //  process.env.NODE_ENV !== 'production' && process.env.JEST_WORKER_ID == null,
    backend: {
      backends: [...(ENABLE_LOCIZE ? [LocizeBackend, LocizeBackend] : []), HttpBackend],
      backendOptions: [
        ...(ENABLE_LOCIZE
          ? [
              {
                projectId: LOCIZE_PROJECT_ID,
                referenceLng: 'en',
                version: LOCIZE_VERSION,
              },
              // Fallback to latest if keys haven't been translated
              {
                projectId: LOCIZE_PROJECT_ID,
                referenceLng: 'en',
                version: 'latest',
              },
            ]
          : []),
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ].filter(Boolean),
    },
    ns: (isSpringtimeUrl()
      ? [
          TranslationNamespaces.Core,
          TranslationNamespaces.Account,
          TranslationNamespaces.Authentication,
          TranslationNamespaces.Checkout,
          TranslationNamespaces.Dashboard,
          TranslationNamespaces.Springtime,
        ]
      : Object.values(TranslationNamespaces).filter(
          (key) => key !== TranslationNamespaces.Springtime,
        )) as `${TranslationNamespaces}`[],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    postProcess: ['prefixTesting'],
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
