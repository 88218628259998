/* eslint-disable i18next/no-literal-string */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle({
  a: {
    color: 'inherit',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '*': {
    outlineOffset: '2px !important',
  },
});
