import {
  ChargebeeSubscriptionResponseDto,
  GetPlansResponseDto,
  SiteSubscriptionStatus,
  SubscriptionDTO,
} from '@audioeye/mono-client';
import { differenceInHours, formatDistanceStrict, isFuture, isPast } from 'date-fns';
import i18n from 'i18n';

const t = i18n.getFixedT(null, 'translation');

// Determine if trial subscription has lapsed
export const trialExpired = (subscription?: ChargebeeSubscriptionResponseDto): boolean =>
  subscription?.status === 'cancelled' && subscription?.cancel_reason === 'no_card';

export const trialDaysLeft = (subscription?: ChargebeeSubscriptionResponseDto): number =>
  Math.ceil(
    (subscription?.trial_end ? differenceInHours(new Date(subscription.trial_end * 1000), new Date()) : 0) / 24,
  );

export const trialTimeLeft = (subscription?: ChargebeeSubscriptionResponseDto): string | undefined => {
  if (!subscription?.trial_end || (subscription.trial_end && isPast(subscription.trial_end * 1000))) return undefined;

  const remainingTrialDays = trialDaysLeft(subscription);
  const lessThanOneDayRemaining = remainingTrialDays === 0;

  return lessThanOneDayRemaining
    ? formatDistanceStrict(new Date(subscription.trial_end * 1000), new Date())
    : remainingTrialDays.toString();
};

export const calculatedStatus = (
  subscription: ChargebeeSubscriptionResponseDto | undefined,
  subscriptionStatus: SiteSubscriptionStatus | undefined,
): SiteSubscriptionStatus | 'trial_lapsed' | undefined => {
  // Determine if a trial subscription has lapsed, otherwise pass status through
  if (trialExpired(subscription)) {
    return 'trial_lapsed';
  }

  if (subscriptionStatus === SiteSubscriptionStatus.CANCELLATION_REQUESTED) {
    return subscriptionStatus;
  }

  // return subscription.status;
  return subscription?.status != null ? subscription?.status : subscriptionStatus;
};

export const cancelledByUser = (subscription?: ChargebeeSubscriptionResponseDto): boolean =>
  subscription?.status === SiteSubscriptionStatus.CANCELLED && !subscription?.cancel_reason;

export const planAmount = (plan: GetPlansResponseDto) =>
  t('planAmount', '{{price, currency}} / {{paymentPeriodUnit}} x {{paymentPeriod}}', {
    price: plan.price != null ? Math.round(plan.price / 100) : 0,
    paymentPeriod: plan.paymentPeriod,
    paymentPeriodUnit: plan.paymentPeriodUnit,
    formatParams: {
      price: { currency: 'USD', minimumFractionDigits: 0, style: 'currency' },
    },
  });

export const cancellationScheduled = (subscription?: ChargebeeSubscriptionResponseDto): boolean =>
  Boolean(subscription && subscription.cancelled_at && isFuture(subscription.cancelled_at * 1000));

export const isSubscriptionCancelled = (subscription?: SubscriptionDTO): boolean =>
  !subscription || subscription?.status === SiteSubscriptionStatus.CANCELLED;
