import {
  AccountDTO,
  AccountPaginatedResponseDTO,
  createAccount,
  findOneAccount,
  getAccountPaymentDetails,
  getAccounts,
  MutateAccountDTO,
  PaymentDetailsDTO,
  QueryAccountPaginateEntitiesDTO,
  removeAccount,
  updateAccount,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';

import { convertAxiosResponse } from '../util/convertAxiosResponse';

export enum AccountCacheKeys {
  All = 'all_accounts',
  ByID = 'account_by_id',
  PaymentDetailsByID = 'payment_details_by_account_id',
}

export const useGetAccounts = (
  dto: QueryAccountPaginateEntitiesDTO,
  isQueryEnabled: boolean = true,
): UseInfiniteQueryResult<InfiniteData<AccountPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [AccountCacheKeys.All, dto],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(getAccounts({ query: { ...dto, cursor: pageParam == null ? undefined : pageParam } })),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isQueryEnabled,
    initialPageParam: '',
  });

export const useGetAccountPaymentDetails = (
  accountId: string | undefined | null,
): UseQueryResult<PaymentDetailsDTO, AxiosError> =>
  useQuery({
    queryKey: [AccountCacheKeys.PaymentDetailsByID, accountId],
    queryFn: () => convertAxiosResponse(getAccountPaymentDetails({ path: { id: nullthrows(accountId) } })),
    enabled: !!accountId,
  });

export const useGetAccountById = (accountId: string | undefined | null): UseQueryResult<AccountDTO, AxiosError> =>
  useQuery({
    queryKey: [AccountCacheKeys.ByID, accountId],
    queryFn: () => convertAxiosResponse(findOneAccount({ path: { id: nullthrows(accountId) } })),
    enabled: !!accountId,
  });

export const useCreateAccount = (): UseMutationResult<AccountDTO, AxiosError, MutateAccountDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createAccount({ body: dto })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.All] });
    },
  });
};

export const useUpdateAccount = (): UseMutationResult<AccountDTO, AxiosError, MutateAccountDTO & { id: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => convertAxiosResponse(updateAccount({ path: { id }, body: dto })),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.ByID, id] });
    },
  });
};

export const useDeleteAccount = (): UseMutationResult<unknown, AxiosError, { compartmentId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ compartmentId }) => convertAxiosResponse(removeAccount({ path: { id: compartmentId } })),
    onSuccess: async (_, { compartmentId }) => {
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.ByID, compartmentId] });
    },
  });
};
