import { SSOConfigurationScope } from '@audioeye/auth-client';
import {
  createIntegration,
  getIntegrationById,
  getIntegrationBySsoConfigId,
  getIntegrations,
  IntegrationDTO,
  IntegrationPaginatedResponseDTO,
  MutateIntegrationDTO,
  QueryIntegrationPaginateEntitiesDTO,
  removeIntegration,
  updateIntegration,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import i18n from 'i18n';
import nullthrows from 'nullthrows';

import { convertAxiosResponse } from '../util/convertAxiosResponse';
import { VENASTA_SSO_CONFIG_IDS } from '../util/VENDASTA_SSO_CONFIG_IDS';
import { useGetPublicSSOConfigurationById } from './ssoQueries';

export enum IntegrationCacheKeys {
  All = 'all_integrations',
  ByID = 'integration_by_id',
  BySSOConfigID = 'integration_by_sso_config_id',
}

const t = i18n.getFixedT(null, 'translation');

export const useGetIntegrations = (
  dto: QueryIntegrationPaginateEntitiesDTO,
  isQueryEnabled: boolean = true,
): UseInfiniteQueryResult<InfiniteData<IntegrationPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [IntegrationCacheKeys.All, dto],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(getIntegrations({ query: { ...dto, cursor: pageParam == null ? undefined : pageParam } })),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isQueryEnabled,
    initialPageParam: '',
  });

export const useGetIntegrationById = (
  integrationId: string | undefined | null,
): UseQueryResult<IntegrationDTO, AxiosError> =>
  useQuery({
    queryKey: [IntegrationCacheKeys.ByID, integrationId],
    queryFn: () => convertAxiosResponse(getIntegrationById({ path: { id: nullthrows(integrationId) } })),
    enabled: !!integrationId,
  });

export const useGetIntegrationBySSOConfigId = (
  ssoConfigId: string | undefined | null,
): UseQueryResult<IntegrationDTO, AxiosError> => {
  const queryClient = useQueryClient();
  const { data: publicSSOConfig } = useGetPublicSSOConfigurationById(ssoConfigId);
  return useQuery({
    queryKey: [IntegrationCacheKeys.ByID, ssoConfigId],
    queryFn: async () => {
      // Throw here instead of calling the endpoint for legacy Vendasta SSO config IDs
      if (ssoConfigId && VENASTA_SSO_CONFIG_IDS.includes(ssoConfigId)) {
        throw new AxiosError(
          t('useGetIntegrationBySSOConfigId.errorMessage', 'Integration does not exist for legacy Vendasta'),
          '404',
        );
      }

      const integration = await convertAxiosResponse(
        getIntegrationBySsoConfigId({ path: { ssoConfigurationId: nullthrows(ssoConfigId) } }),
      );
      queryClient.setQueryData([IntegrationCacheKeys.ByID, integration.id], integration);
      return integration;
    },
    enabled:
      !!ssoConfigId &&
      publicSSOConfig != null &&
      publicSSOConfig.configurationScope !== SSOConfigurationScope.ORGANIZATION,
    retry: false,
  });
};

export const useCreateIntegration = (): UseMutationResult<IntegrationDTO, AxiosError, MutateIntegrationDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createIntegration({ body: dto })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [IntegrationCacheKeys.All] });
    },
  });
};

export const useUpdateIntegration = (): UseMutationResult<
  IntegrationDTO,
  AxiosError,
  MutateIntegrationDTO & { id: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => convertAxiosResponse(updateIntegration({ path: { id }, body: dto })),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: [IntegrationCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [IntegrationCacheKeys.ByID, id] });
    },
  });
};

export const useRemoveIntegration = (): UseMutationResult<unknown, AxiosError, { integrationId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ integrationId }) => convertAxiosResponse(removeIntegration({ path: { id: integrationId } })),
    onSuccess: async (_, { integrationId }) => {
      await queryClient.invalidateQueries({ queryKey: [IntegrationCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [IntegrationCacheKeys.ByID, integrationId] });
    },
  });
};
