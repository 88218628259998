import {
  BannerSetDTO,
  BannerStatisticsDTO,
  CookieConsentBannerDTO,
  createSubBrand,
  findOneSubBrand,
  getBannerStatistics,
  getDefaultBannerForSubBrand,
  getDefaultBannerSetForSubBrand,
  getDefaultSubBrand,
  getParagonIntegrationToken,
  getSubBrands,
  mergeSubBrand,
  MutateCookieConsentBannerDTO,
  MutateDefaultBannerSetDTO,
  MutateSubBrandDTO,
  QuerySubBrandPaginateEntitiesDTO,
  removeSubBrand,
  SubBrandDTO,
  SubBrandPaginatedResponseDTO,
  updateDefaultBannerForSubBrand,
  updateDefaultBannerSetForSubBrand,
  updateSubBrand,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { useEffect } from 'react';

import { SpringtimeBannerMutationKeys, SpringtimeMutationKeys } from '../springtime/enums/enums';
import { convertAxiosResponse } from '../util/convertAxiosResponse';
// eslint-disable-next-line import/no-cycle
import { SiteCacheKeys } from './sites/siteQueries';
import { SubscriptionCacheKeys } from './subscription/subscriptionQueries';

enum SubBrandCacheKeys {
  All = 'all_sub_brands',
  ByID = 'sub_brand_by_id',
  SelectedSubBrand = 'selected_sub_brand',
  DefaultSubBrand = 'default_sub_brand',
  DefaultBanner = 'default_banner',
  DefaultBannerSet = 'default_banner_set',
  BannerStatistics = 'banner_statistics',
  ParagonIntegrationToken = 'paragon_integration_token',
}

export const useCurrentSubBrandId = (): [string | undefined, (value: string) => void] => {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [SubBrandCacheKeys.SelectedSubBrand],
    queryFn: () => null,
    initialData: null,
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return [
    data ?? undefined,
    (newValue: string) => {
      queryClient.setQueryData([SubBrandCacheKeys.SelectedSubBrand], newValue);
    },
  ];
};

export const useGetDefaultSubBrand = (accountId: string | undefined): UseQueryResult<SubBrandDTO, AxiosError> => {
  const [currentSubBrandId, setCurrentSubBrandId] = useCurrentSubBrandId();

  const query = useQuery<SubBrandDTO, AxiosError>({
    queryKey: [SubBrandCacheKeys.DefaultSubBrand, accountId],
    queryFn: () => convertAxiosResponse(getDefaultSubBrand({ path: { id: nullthrows(accountId) } })),
    enabled: !!accountId,
  });

  useEffect(() => {
    if (currentSubBrandId || !query.data?.id) {
      return;
    }

    setCurrentSubBrandId(query.data?.id);
  }, [currentSubBrandId, query.data]);

  return query;
};

export const useGetSubBrands = (
  dto: QuerySubBrandPaginateEntitiesDTO,
  isEnabled?: boolean,
): UseInfiniteQueryResult<InfiniteData<SubBrandPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SubBrandCacheKeys.All, dto],
    queryFn: ({ pageParam }) => convertAxiosResponse(getSubBrands({ query: { ...dto, cursor: pageParam } })),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isEnabled ?? true,
    initialPageParam: '',
  });

export const useGetSubBrandById = (subBrandId: string | undefined | null): UseQueryResult<SubBrandDTO, AxiosError> =>
  useQuery({
    queryKey: [SubBrandCacheKeys.ByID, subBrandId],
    queryFn: () => convertAxiosResponse(findOneSubBrand({ path: { id: nullthrows(subBrandId) } })),
    enabled: !!subBrandId,
  });

export const useCreateSubBrand = (): UseMutationResult<SubBrandDTO, AxiosError, MutateSubBrandDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(createSubBrand({ body: dto })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.All] });
    },
  });
};

export const useMergeSubBrandIntoSubBrand = (): UseMutationResult<
  unknown,
  AxiosError,
  { fromSubBrandId: string; toSubBrandId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ fromSubBrandId, toSubBrandId }) =>
      convertAxiosResponse(mergeSubBrand({ path: { id: fromSubBrandId, idToMergeTo: toSubBrandId } })),
    onSuccess: async (_, { fromSubBrandId, toSubBrandId }) => {
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.ByID, fromSubBrandId] });
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.ByID, toSubBrandId] });
      await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSitesPaginated] });
      await queryClient.invalidateQueries({ queryKey: [SubscriptionCacheKeys.All] });
    },
  });
};

export const useUpdateSubBrand = (): UseMutationResult<SubBrandDTO, AxiosError, MutateSubBrandDTO & { id: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => convertAxiosResponse(updateSubBrand({ path: { id }, body: dto })),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.ByID, id] });
    },
  });
};

export const useRemoveSubBrand = (): UseMutationResult<unknown, AxiosError, { subBrandId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ subBrandId }) => convertAxiosResponse(removeSubBrand({ path: { id: subBrandId } })),
    onSuccess: async (_, { subBrandId }) => {
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.ByID, subBrandId] });
    },
  });
};

export const useGetDefaultBannerForSubBrand = (
  subBrandId: string | undefined | null,
  enabled = true,
): UseQueryResult<CookieConsentBannerDTO, AxiosError> =>
  useQuery({
    queryKey: [SubBrandCacheKeys.DefaultBanner],
    queryFn: () => convertAxiosResponse(getDefaultBannerForSubBrand({ path: { id: nullthrows(subBrandId) } })),
    enabled: !!subBrandId && enabled,
  });

export const useGetDefaultBannerSetForSubBrand = (
  subBrandId: string | undefined | null,
  enabled: boolean = false,
): UseQueryResult<BannerSetDTO, AxiosError> => {
  return useQuery({
    queryKey: [SubBrandCacheKeys.DefaultBannerSet],
    queryFn: () => convertAxiosResponse(getDefaultBannerSetForSubBrand({ path: { id: nullthrows(subBrandId) } })),
    enabled,
  });
};

export const useUpdateDefaultBannerSetForSubBrand = (
  subBrandId: string | undefined | null,
  bannerMutationKey: SpringtimeBannerMutationKeys,
): UseMutationResult<BannerSetDTO, AxiosError, MutateDefaultBannerSetDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (mutateDefaultBannerSetDto) =>
      convertAxiosResponse(
        updateDefaultBannerSetForSubBrand({ path: { id: nullthrows(subBrandId) }, body: mutateDefaultBannerSetDto }),
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.DefaultBannerSet] });
    },
    mutationKey: [SpringtimeMutationKeys.UPDATE_DEFAULT_BANNER_SET, bannerMutationKey, subBrandId],
  });
};

export const useUpdateDefaultBannerForSubBrand = (
  subBrandId: string | undefined | null,
  bannerMutationKey: SpringtimeBannerMutationKeys,
): UseMutationResult<
  CookieConsentBannerDTO,
  AxiosError,
  { mutateCookieConsentBannerDto: MutateCookieConsentBannerDTO }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ mutateCookieConsentBannerDto }) =>
      convertAxiosResponse(
        updateDefaultBannerForSubBrand({ path: { id: nullthrows(subBrandId) }, body: mutateCookieConsentBannerDto }),
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SubBrandCacheKeys.DefaultBanner] });
    },
    mutationKey: [SpringtimeMutationKeys.UPDATE_DEFAULT_BANNER, bannerMutationKey, subBrandId],
  });
};

export const useGetBannerStatistics = (
  subBrandId: string | undefined | null,
): UseQueryResult<BannerStatisticsDTO, AxiosError> =>
  useQuery({
    queryKey: [SubBrandCacheKeys.BannerStatistics],
    queryFn: () => convertAxiosResponse(getBannerStatistics({ path: { id: nullthrows(subBrandId) } })),
    enabled: !!subBrandId,
  });

export const useGetParagonIntegrationTokenForSubBrand = (subBrandId: string | undefined | null) => {
  return useQuery({
    queryKey: [SubBrandCacheKeys.ParagonIntegrationToken],
    queryFn: () => convertAxiosResponse(getParagonIntegrationToken({ path: { id: nullthrows(subBrandId) } })),
    enabled: !!subBrandId,
  });
};
