import { useQueryClient } from '@tanstack/react-query';
import { useResolveStatsigUser } from 'hooks/useResolveStatsigUser';
import React, { useEffect } from 'react';
import { AuthStorage } from 'services/api/auth/AuthStorage';
import { AEStatsigClient } from 'util/AEStatsigClient';
import { StatsigOverrideAdaptor } from 'util/StatsigOverrideAdaptor';

const overrideAdapter = AEStatsigClient.getContext().options.overrideAdapter as StatsigOverrideAdaptor;

export const WhiteScreenOfDeathFix = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useResolveStatsigUser();
  const queryClient = useQueryClient();

  // If we fail to load the page for whatever reason, this will clear the session and log the user out
  // See https://audioeye.atlassian.net/browse/IN-8170
  let timeout: NodeJS.Timeout | null = null;
  const resetTimeout = () => {
    if (timeout != null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };
  useEffect(() => {
    if (!isLoading) {
      resetTimeout();
      return;
    }

    timeout = setTimeout(
      () => {
        AuthStorage.clearSession();
        overrideAdapter.removeAllOverrides();
        queryClient.clear();
      },
      process.env.NODE_ENV === 'e2e' ? 20_000 : 6_000,
    );

    return () => resetTimeout();
  }, [isLoading]);

  if (typeof window === 'undefined') {
    return null;
  }

  return <>{children}</>;
};
