import {
  A11yFixRunMode,
  addExclusion,
  addRemediation,
  deleteExclusion,
  deleteFile,
  deleteFiles,
  getComponentTypesForSiteGroup,
  getOverviewForSiteGroup,
  getRemediationV2,
  getTypesForSiteGroup,
  type ManualRemediationType,
  moveRemediation,
  MoveRemediationDTO,
  RulesRemediationDto,
  RulesRemediationExclusionDto,
  RulesRemediationType,
  RulesRemediationTypesFileDto,
  SiteGroupType,
  SiteGroupVersion,
  updateRemediationV2,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { convertAxiosResponse } from 'util/convertAxiosResponse';

export enum RemediationKey {
  RemediationStableId = 'remediationStableId',
  RulesFiles = 'rules_files',
  RulesRemsTypes = 'rules_rems_types',
  ComponentRemsTypes = 'component_rems_types',
}

export type UseGetRuleRemediationsArs = {
  siteGroupId?: string;
  siteGroupType: SiteGroupType;
  reportingVersion?: string;
};

const OVERVIEW_QUERY_KEY = 'useGetSiteGroupOverview';

export const siteGroupOverviewKey = (
  siteGroupId: string | undefined,
  siteGroupVersion: SiteGroupVersion = SiteGroupVersion.WORKING,
) => [OVERVIEW_QUERY_KEY, siteGroupId, siteGroupVersion];

export const getFilesKey = (
  siteGroupId: string | undefined,
  siteGroupVersion: SiteGroupVersion,
): (string | undefined)[] => [RemediationKey.RulesFiles, siteGroupId, siteGroupVersion];

export const useGetRemediationV2 = ({
  stableId,
  siteGroupVersion,
}: {
  stableId: string | undefined;
  siteGroupVersion: SiteGroupVersion | undefined;
}): UseQueryResult<RulesRemediationDto> => {
  return useQuery({
    queryKey: [RemediationKey.RemediationStableId, { stableId, siteGroupVersion }],
    queryFn: () =>
      convertAxiosResponse(
        getRemediationV2({
          path: { stableId: nullthrows(stableId) },
          query: { version: nullthrows(siteGroupVersion) },
        }),
      ),
    enabled: stableId != null && siteGroupVersion != null,
  });
};

export const useGetSiteGroupRulesOverview = ({
  siteGroupId,
  siteGroupVersion,
}: {
  siteGroupId: string | undefined;
  siteGroupVersion: SiteGroupVersion | undefined;
}): UseQueryResult<RulesRemediationDto[]> => {
  return useQuery({
    queryKey: siteGroupOverviewKey(siteGroupId, siteGroupVersion),
    queryFn: () =>
      convertAxiosResponse(
        getOverviewForSiteGroup({
          path: { siteGroupId: nullthrows(siteGroupId), siteGroupVersion: nullthrows(siteGroupVersion) },
        }),
      ),
    enabled: siteGroupId != null && siteGroupVersion != null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreateExclusion = (): UseMutationResult<
  RulesRemediationExclusionDto,
  AxiosError,
  { siteGroupId: string; stableId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, stableId }) =>
      convertAxiosResponse(
        addExclusion({
          path: {
            siteGroupId,
            stableId,
          },
        }),
      ),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useDeleteExclusion = (): UseMutationResult<
  unknown,
  AxiosError,
  { siteGroupId: string; stableId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, stableId }) =>
      convertAxiosResponse(deleteExclusion({ path: { siteGroupId, stableId } })),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};
export const useCreateRulesRemediation = (): UseMutationResult<
  RulesRemediationDto,
  AxiosError,
  {
    siteGroupId: string;
    contents: string;
    name?: string;
    type?: RulesRemediationType;
    viewingSiteGroupId: string;
    ruleName?: string;
    autocompleteId?: string;
    runMode?: A11yFixRunMode;
  }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      siteGroupId,
      contents,
      name,
      type = RulesRemediationType.RULE,
      ruleName,
      autocompleteId,
      runMode,
    }) =>
      convertAxiosResponse(
        addRemediation({
          path: { siteGroupId },
          body: { name, type: type.toString() as ManualRemediationType, contents, ruleName, autocompleteId, runMode },
        }),
      ),
    onSuccess: async (_, { viewingSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(viewingSiteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(viewingSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useUpdateRulesRemediation = (): UseMutationResult<
  RulesRemediationDto,
  AxiosError,
  {
    name?: string;
    fileId: string;
    contents: string;
    viewingSiteGroupId: string;
    ruleName?: string;
    autocompleteId?: string;
    runMode?: A11yFixRunMode;
  }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId, name, contents, ruleName, autocompleteId, runMode }) =>
      convertAxiosResponse(
        updateRemediationV2({
          path: { stableId: fileId },
          body: { name, contents, ruleName, autocompleteId, runMode },
        }),
      ),
    onSuccess: async (_, { viewingSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(viewingSiteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(viewingSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useMoveRulesRemediation = (): UseMutationResult<
  MoveRemediationDTO,
  AxiosError,
  {
    stableId: string;
    siteGroupId: string;
    currentSiteGroupId: string;
  }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ stableId, siteGroupId }) =>
      convertAxiosResponse(moveRemediation({ path: { stableId, siteGroupId } })),
    onSuccess: async (_, { siteGroupId, currentSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(currentSiteGroupId),
      });
      await queryClient.removeQueries({
        queryKey: getFilesKey(currentSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useRulesRemediationTypes = (
  siteGroupId: string | undefined,
): UseQueryResult<RulesRemediationTypesFileDto[]> =>
  useQuery({
    queryKey: [RemediationKey.RulesRemsTypes, siteGroupId],
    queryFn: async () => convertAxiosResponse(getTypesForSiteGroup({ path: { siteGroupId: nullthrows(siteGroupId) } })),
    enabled: siteGroupId != null,
  });

export const useComponentRemediationTypes = (
  siteGroupId: string | undefined,
): UseQueryResult<RulesRemediationTypesFileDto[]> =>
  useQuery({
    queryKey: [RemediationKey.ComponentRemsTypes, siteGroupId],
    queryFn: async () =>
      convertAxiosResponse(getComponentTypesForSiteGroup({ path: { siteGroupId: nullthrows(siteGroupId) } })),
    enabled: siteGroupId != null,
  });

export const useDeleteRemediationFile = (): UseMutationResult<
  unknown,
  AxiosError,
  { siteGroupId: string; fileId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId }) => convertAxiosResponse(deleteFile({ path: { fileId } })),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useDeleteRemediationFiles = (): UseMutationResult<
  unknown,
  AxiosError,
  { siteGroupIds: string[]; fileIds: string[] }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileIds }) => convertAxiosResponse(deleteFiles({ body: fileIds })),
    onSuccess: async (_, { siteGroupIds }) => {
      await Promise.all(
        siteGroupIds.map(async (siteGroupId) => {
          await queryClient.invalidateQueries({
            queryKey: siteGroupOverviewKey(siteGroupId),
          });
          await queryClient.invalidateQueries({
            queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
          });
        }),
      );
    },
  });
};
