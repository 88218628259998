import { AuthenticationSessionDTO } from '@audioeye/auth-client';
import {
  addUserAgreement,
  adminMarkUserAsDeleted,
  CreateUserAgreementDTO,
  CreateUserDto,
  generateUserApiKey,
  getUser,
  getUsers,
  migrateUserToAccount,
  MigrateUserToAccountDTO,
  provisionUserFromSso,
  ProvisionUserFromSSODTO,
  QueryUserPaginateEntitiesDTO,
  updateUser,
  UpdateUserDto,
  UserAgreementResponseDTO,
  UserDTO,
  UserPaginatedResponseDTO,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { convertAxiosResponse } from 'util/convertAxiosResponse';

import { track } from '../../services/analytics/analytics';
import { auth } from '../../services/api/auth';
import { AuthStorage } from '../../services/api/auth/AuthStorage';
import { AnalyticsEvent } from '../../types/analytics';
// eslint-disable-next-line import/no-cycle
import { useSetAppUserId } from '../appUser/appUserQueries';
// eslint-disable-next-line import/no-cycle
import { AuthCacheKeys } from '../auth/authQueries';

export enum UserCacheKeys {
  All = 'all_users',
  UserData = 'user_data',
  User = 'user',
  Users = 'users',
  IssueReporting = 'issue_reporting',
  ProvisionUserAfterSSO = 'provision_user_after_sso',
}

export const useGetUsers = (
  dto: QueryUserPaginateEntitiesDTO,
  isEnabled: boolean = true,
): UseInfiniteQueryResult<InfiniteData<UserPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [UserCacheKeys.All, dto],
    queryFn: ({ pageParam }) =>
      convertAxiosResponse(getUsers({ query: { ...dto, cursor: pageParam == null ? undefined : pageParam } })),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isEnabled,
    initialPageParam: '',
  });

export const useGetUserById = (
  userId: string | null | undefined,
  shouldRefetchOnMount = false,
): UseQueryResult<UserDTO, AxiosError> =>
  useQuery({
    queryKey: [UserCacheKeys.User, userId],
    queryFn: () => convertAxiosResponse(getUser({ path: { id: nullthrows(userId) } })),
    enabled: !!userId,
    refetchOnMount: shouldRefetchOnMount,
  });

export const useRegisterUser = (): UseMutationResult<UserDTO, AxiosError, CreateUserDto> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => auth.register(params),
    onSuccess: async (result) => {
      if (!result) {
        return;
      }
      // Clear out the users list if we add a new user
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.Users] });
    },
  });
};

export const useUpdateUser = (): UseMutationResult<UserDTO, AxiosError, { userId: string; data: UpdateUserDto }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, data: dto }) =>
      convertAxiosResponse(
        updateUser({
          path: {
            id: userId,
          },
          body: dto,
        }),
      ),
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.Users] });
    },
  });
};

export const useMigrateUserToAccount = (): UseMutationResult<unknown, AxiosError, MigrateUserToAccountDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(migrateUserToAccount({ body: dto })),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.Users] });
    },
  });
};

export const useGenerateApiKey = (): UseMutationResult<UserDTO, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => convertAxiosResponse(generateUserApiKey({ path: { id } })),
    onSuccess: async (_result, userId) => {
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, userId] });
    },
  });
};

export const useMarkUserAsDeleted = (): UseMutationResult<unknown, Error, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId) => convertAxiosResponse(adminMarkUserAsDeleted({ path: { id: userId } })),
    onSuccess: async (_result, userId) => {
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, userId] });
    },
  });
};

export const useProvisionUserFromSSO = (): UseMutationResult<
  unknown,
  AxiosError,
  Omit<ProvisionUserFromSSODTO, 'payload'> & AuthenticationSessionDTO
> => {
  const setAppUser = useSetAppUserId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => convertAxiosResponse(provisionUserFromSso({ body: dto })),
    onSuccess: async (_, dto) => {
      if (!dto) {
        return;
      }
      AuthStorage.setSession(dto);
      queryClient.setQueryData([AuthCacheKeys.AuthSession], dto);
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, dto.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, dto.userId] });
      setAppUser(dto.userId);
      track(AnalyticsEvent.LOGIN_SUCCESS, { label: AnalyticsEvent.LOGIN_SUCCESS_EMAIL_LABEL });
    },
  });
};

export const useStoreUserAgreement = (): UseMutationResult<
  UserAgreementResponseDTO,
  AxiosError,
  CreateUserAgreementDTO
> =>
  useMutation({
    mutationFn: (dto) => convertAxiosResponse(addUserAgreement({ body: dto })),
  });
