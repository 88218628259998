import { SiteDTO } from '@audioeye/mono-client';
import { datadogLogs } from '@datadog/browser-logs';
import isFQDN from 'validator/lib/isFQDN';

/**
 * General use formatters
 */

export type Maybe<T> = T | undefined;

const ensureString = (str: Maybe<string>) => str || '';

export const titleCase = (str: Maybe<string>, splitRegex = /\w\S*/g) =>
  ensureString(str).replace(splitRegex, (s) => `${s.charAt(0).toUpperCase()}${s.substr(1).toLowerCase()}`);

export const sentenceCase = (str?: string) => {
  const [f, ...rest] = ensureString(str).toLowerCase();
  return [f.toUpperCase(), ...rest].join('');
};

export const humanizeSkeletonCase = (str: Maybe<string>, splitRegex = /(^|_)(\w)/g) =>
  ensureString(str).replace(splitRegex, (_, $1, $2) => `${$1 && ' '}${$2.toUpperCase()}`);

export const humanizeCamelCase = (input: string) => {
  // log to DD when the input param is null
  if (input != null) {
    // eslint-disable-next-line i18next/no-literal-string
    datadogLogs.logger.error('humanizeCamelCase: input param is null');
  }
  return (
    input
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
};

export const convertHexToRGBA = (hex: string, opacity: number) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const longHex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);
  const h = longHex.replace('#', '');
  const r = parseInt(h.substring(0, 2), 16);
  const g = parseInt(h.substring(2, 4), 16);
  const b = parseInt(h.substring(4, 6), 16);

  // eslint-disable-next-line i18next/no-literal-string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// eslint-disable-next-line i18next/no-literal-string
export const domainToURL = (domain: string) => `https://${domain}`;

export const extractDomainFromUrl = (url: string) => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

export const urlWithoutProtocol = (url: string) => url.replace(/^https?:\/\//i, '');

// eslint-disable-next-line i18next/no-literal-string
export const applyProtocolToUrl = (site: SiteDTO) => (site.secure ? `https://${site.domain}` : `http://${site.domain}`);

const schemaRegex = /^https?:\/\//;
export const urlValidator = (url: string | null | undefined) => {
  if (typeof url !== 'string') {
    return false;
  }
  try {
    // eslint-disable-next-line i18next/no-literal-string
    const { hostname } = new URL(schemaRegex.test(url) ? url : `http://${url}`);
    return isFQDN(hostname, { allow_underscores: true });
  } catch {
    return false;
  }
};
