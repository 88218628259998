import { useParams as useParams_Tanstack } from '@tanstack/react-router';
import { useParams as useParams_RR } from 'react-router-dom';

type UseParamsType = typeof useParams_RR;

const useParamsWrapper: UseParamsType = () => {
  return useParams_Tanstack({ strict: false } as any);
};

export const useParams_MIGRATION: UseParamsType =
  process.env.VITE_TANSTACK_NAVIGATION != null ? useParamsWrapper : useParams_RR;
