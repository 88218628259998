/* eslint-disable i18next/no-literal-string */
import { APP_CONFIG } from 'config';
import React from 'react';
import Helmet from 'react-helmet';

const DATADOG_SYNTHETIC_USER_AGENT_SUFFIX = 'DatadogSynthetics';

const scripts = {
  // eslint-disable-next-line max-len
  production: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("3639159012");`,
  // eslint-disable-next-line max-len
  stage: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("520761907");`,
};

export const HeapScript = () => {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return null;
  }

  const isDatadogSynthetic = window.navigator.userAgent.includes(DATADOG_SYNTHETIC_USER_AGENT_SUFFIX);
  if (isDatadogSynthetic) {
    return null;
  }

  // Don't load Heap for the legacy accessibility statements.
  // Can't use react router location, since this component is not nested in the Router.
  if (window.location.pathname.includes('/marketplace/accessibilitystatement')) {
    return null;
  }

  if (APP_CONFIG.site.siteUrl.includes('localhost') === true) {
    return null;
  }

  const env = process.env.GATSBY_ACTIVE_ENV ?? process.env.NODE_ENV;
  if (env !== 'production' && env !== 'stage') {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">{scripts[env]}</script>
    </Helmet>
  );
};
