import {
  CompartmentApi,
  CompartmentDTO,
  CompartmentPaginatedResponseDTO,
  QueryCompartmentPaginateEntitiesDTO,
} from '@audioeye/auth-client';

import { authClient } from '../client';

const compartmentApi = new CompartmentApi(undefined, undefined, authClient);

export const getCompartmentById = async (compartmentId: string): Promise<CompartmentDTO> => {
  const result = await compartmentApi.getCompartmentById(compartmentId);

  return result.data;
};

export const getCompartments = async (
  dto: QueryCompartmentPaginateEntitiesDTO,
): Promise<CompartmentPaginatedResponseDTO> => {
  const { pageSize, orderBy, sortDirection, cursor, filterBy, shouldOnlyReturnTenancies, parentCompartmentId } = dto;
  const result = await compartmentApi.getCompartments(
    pageSize,
    orderBy,
    sortDirection,
    cursor,
    filterBy,
    shouldOnlyReturnTenancies,
    parentCompartmentId,
  );

  return result.data;
};
