/* eslint-disable i18next/no-literal-string */
import { APP_CONFIG } from 'config';
import React from 'react';
import Helmet from 'react-helmet';

export function ChargeBeeScript() {
  const { site } = APP_CONFIG.chargebee;

  return (
    <Helmet>
      <script>
        {`
                function initChargebee() {
                  Chargebee.init({ site: '${site}' });
                }`}
      </script>
      <script
        type="text/javascript"
        src="https://js.chargebee.com/v2/chargebee.js"
        onLoad={'initChargebee()' as any}
        data-cb-site={site}
      />
    </Helmet>
  );
}
