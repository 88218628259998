/* eslint-disable i18next/no-literal-string */
import { APP_CONFIG } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet';

export function HubspotScript() {
  const { portalId } = APP_CONFIG.hubspot;
  const source = `https://js.hs-scripts.com/${portalId}.js`;

  return (
    <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src={source} />
    </Helmet>
  );
}
