import { i18n, PostProcessorModule, TOptions } from 'i18next';

export const prefixPostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  // eslint-disable-next-line i18next/no-literal-string
  name: 'prefixTesting',
  process(value: string, _key: string | string[], _options: TOptions, translator: i18n): string {
    return translator.language === 'testing' ? `TK_${value}` : value;
  },
};
